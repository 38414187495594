<template>
  <BaseForm :title="title" :mod="mod" :flat="flat" :divider="divider" :dialog="dialog" :loading="loading" @save="save">
    <template #content>
      <v-container fluid>
        <v-row><v-col> <v-switch v-model="form.automaticBilling"
              label="Ativar/desativar cobrança automática"></v-switch> </v-col></v-row>

        <v-row align="center">
          <v-col sm="6">
            <v-select class="required mb-2 mr-sm-2 mb-sm-0" :loading="loadingPlans" v-model="form.PlanId"
              @change="getPlanFeatures" :items="plans" label="Plano:"
              placeholder="Selecione o plano em que deseja anunciar"
              :disabled="mod === 'update' && form.automaticBilling">
            </v-select>
          </v-col>
          <v-col sm="6">
            <v-select :loading="loadingTimeIntervals" :items="timeIntervals" label="Intervalo de pagamento"
              v-model="form.TimeIntervalForPaymentId" :disabled="mod === 'update' && form.automaticBilling"></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-autocomplete class="required" v-model="form.CompanyId" :loading="loadignCompanies" label="Empresa"
              :items="companies" clearable></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select class="required" v-model="form.AdCategoryId" :items="adCategoryOptions"
              :loading="loadingAdCategories" label="Categoria"
              placeholder="Selecione a categoria em que deseja anunciar"></v-select>
          </v-col>
        </v-row>

        <v-row v-if="facilitiesIsAllowed">
          <v-col>
            <v-select class="required" v-model="selectedFacilities" :items="companyFacilitiesOptions"
              :loading="loadingFacilities" attach chips label="Facilidades" multiple></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field v-model="form.phone" label="Telefone principal" v-mask="'(##) #####-####'"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="form.whatsapp" label="Whatsapp" v-mask="'(##) #####-####'"
              prepend-icon="mdi-whatsapp"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" v-if="instagramIsAllowed">
            <v-text-field label="Instagram: " v-model="form.instagram" prepend-icon="mdi-instagram"
              placeholder="cole aqui o link do Instagram"></v-text-field>
          </v-col>
          <v-col cols="6" v-if="facebookIsAllowed">
            <v-text-field label="Facebook: " v-model="form.facebook" prepend-icon="mdi-facebook"
              placeholder="cole aqui o link do Facebook"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field v-model="form.site" label="Site"></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="informationIsAllowed">
          <v-col>
            <v-textarea v-model="form.description" label="Informações:"
              placeholder="Informe demais informações pertinentes ao seu anúncio" rows="3"></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h3>Endereço</h3>
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10">
            <v-text-field class="required" label="Logradouro" v-model="form.address" autocomplete="new-password"
              id="address" maxlength="254"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field label="Número" v-model="form.number" autocomplete="new-password" id="address-number"
              class="required"></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col cols="8">
            <v-text-field class="required" label="Bairro" v-model="form.district" autocomplete="new-password"
              id="district"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Complemento" v-model="form.complement" autocomplete="new-password"
              id="complement"></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col cols="4">
            <v-text-field class="required" label="Cep:" v-model="form.zipCode" autocomplete="new-password"
              placeholder="xxxxx-xxx" v-mask="'#####-###'"></v-text-field>
          </v-col>
          <v-col cols="8">
            <v-select class="required mb-2 mr-sm-2 mb-sm-0" :loading="loadingCities" v-model="form.CityId"
              :items="cities" label="Cidade:" id="company-city">
            </v-select>
          </v-col>
        </v-row>

        <v-row v-if="coordinatesIsAllowed">
          <v-col>
            <v-text-field class="required" label="Coordenadas: " v-model="form.coordinate" prepend-icon="mdi-map-marker"
              @click:prepend="getPosition" placeholder="-xx.xxxxxx, -xx.xxxxxx"
              hint="Clique no ícone ao lado para pegar automaticamente"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h3>Telefones secundários</h3>
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="12">
            <v-btn text small @click="addPhone()"> <i class="fa fa-plus"></i> Adicionar telefone </v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-2 d-flex align-center" v-bind:key="index" v-for="(thisPhone, index) in phones">
          <v-col xs="10" sm="10" md="4">
            <v-text-field v-model="thisPhone.number" v-mask="'(##) #####-####'"></v-text-field>
          </v-col>
          <v-col xs="2" sm="2" md="2">
            <v-btn icon small title="Remover este telefone" @click="removePhone(thisPhone.id, index)">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #actions>
      <v-spacer></v-spacer>
      <v-btn v-if="completeRegistration" class="primary darken-1" @click="save('stay')">
        <v-icon right>{{ saveButtonIcon }}</v-icon> {{ saveButtonText }}
      </v-btn>
      <v-btn v-if="mod === 'update' && !completeRegistration" class="primary darken-1" @click="save('stay')">
        <v-icon left>mdi-content-save</v-icon> Salvar</v-btn>
    </template>
  </BaseForm>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";
import { mask } from "vue-the-mask";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  directives: { mask },
  computed: {
    ...mapGetters("adverts", ["validateSpecificFeature"]),
    announcementId() {
      return this.$route.params.id;
    },
    saveButtonText() {
      return this.form.PlanId === 1 || this.form.PlanId === 2 ? "Salvar e publicar" : "Salvar e avançar";
    },
    saveButtonIcon() {
      return this.form.PlanId === 1 || this.form.PlanId === 2 ? "mdi-check" : "mdi-arrow-down-bold";
    },
    facilitiesIsAllowed() {
      return this.validateSpecificFeature(17);
    },
    instagramIsAllowed() {
      return this.validateSpecificFeature(12);
    },
    facebookIsAllowed() {
      return this.validateSpecificFeature(11);
    },
    informationIsAllowed() {
      return this.validateSpecificFeature(13);
    },
    coordinatesIsAllowed() {
      return this.validateSpecificFeature(6);
    },
    whatsAppIsAllowed() {
      return this.validateSpecificFeature(9);
    },
  },
  props: {
    adData: {
      type: Object,
    },
    adFacilities: {
      type: Array,
    },
    adPhones: {
      type: Array,
    },
    completeRegistration: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      loadingFacilities: false,
      loadingAdCategories: false,
      loadignCompanies: false,
      loadingPlans: false,
      loadingCities: false,
      loadingTimeIntervals: false,
      timeIntervals: [],
      adCategoryOptions: [],
      companyFacilitiesOptions: [],
      cities: [],
      companies: [],
      plans: [],
      selectedFacilities: [],
      selectedCategories: [],
      phones: [],
      form: {
        coordinate: null,
        PlanId: null,
        automaticBilling: true,
      },
    };
  },
  methods: {
    ...mapActions("adverts", ["setPlanFeatures", "setAdId"]),
    async save(actionAfterOperation) {
      if (
        !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
          color: "primary",
        }))
      )
        return;

      const errors = this.validateForm();
      // const errors = [];

      if (errors.length) return this.$root.$errorDialog(errors);

      this[this.mod](actionAfterOperation);
    },
    async insert(actionAfterOperation) {
      try {
        this.loading = true;

        let formattedData = await this.formatData();

        const response = await this.$axios.post("/posts", formattedData);

        this.loading = false;

        this.setAdId(response.data.id);

        this.afterInsertion(actionAfterOperation, {
          ...this.form,
          id: response.data.id,
        });

        this.$toast.success("Dados salvos com sucesso");

        this.$emit("saved", 1);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        let formattedData = await this.formatData();

        await this.$axios.put(`/posts/${this.announcementId}`, formattedData);

        this.$toast.success("Dados salvos com sucesso");

        this.$emit("saved", 1);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    formatData() {
      let auxForm = this.form;

      auxForm.phones = this.phones;

      auxForm.facilities = this.selectedFacilities;

      if (this.mod === "insert") auxForm.enabled = true;

      return auxForm;
    },
    validateForm() {
      const errors = [];

      if (!this.form.AdCategoryId) errors.push("Categoria do anúncio obrigatória");

      if (!this.form.PlanId) errors.push("Plano do anúncio obrigatório");

      if (this.form.PlanId !== 1 && !this.form.TimeIntervalForPaymentId) errors.push("Intervalo de pagamento do anúncio obrigatório");

      if (!this.form.CompanyId) errors.push("Empresa do anúncio obrigatória");

      if (!this.selectedFacilities.length && this.form.PlanId === 4) errors.push("Informe ao menos uma facilidade");

      if (!this.form.number) errors.push("O número do endereço é obrigatório");

      if (!this.form.address) errors.push("Logradouro obrigatório");

      if (!this.form.district) errors.push("Bairro obrigatório");

      if (!this.form.zipCode) errors.push("CEP obrigatório");

      if (!this.phones) errors.push("Informe ao menos o telefone principal");

      if (!this.form.coordinate) errors.push("Coordenada obrigatória");

      if (!this.form.CityId) errors.push("Cidade obrigatória");

      return errors;
    },
    async getTimeIntervals() {
      try {
        this.loadingTimeIntervals = true;

        const res = await this.$axios.get("/time-intervals");

        this.setTimeIntervalsData(res.data);

        this.loadingTimeIntervals = false;
      } catch (error) {
        this.loadingTimeIntervals = false;
        this.handleErrors(error);
      }
    },
    setTimeIntervalsData(intervals) {
      this.timeIntervals = intervals.map((t) => {
        return {
          value: t.id,
          text: t.name,
        };
      });
    },
    async getFacilities() {
      try {
        this.loadingFacilities = true;

        const res = await this.$axios.get("/facilities?all=1");

        this.setFacilitiesData(res.data);

        this.loadingFacilities = false;
      } catch (error) {
        this.loadingFacilities = false;
        this.handleErrors(error);
      }
    },
    setFacilitiesData(facilities) {
      this.companyFacilitiesOptions = facilities.map((f) => {
        return {
          value: f.id,
          text: f.name,
        };
      });
    },
    async getCities() {
      try {
        this.loadingCities = true;

        const res = await this.$axios.get("/cities");

        this.setCitiesData(res.data);

        this.loadingCities = false;
      } catch (error) {
        this.loadingCities = false;
        this.handleErrors(error);
      }
    },
    setCitiesData(cities) {
      this.cities = cities.map((c) => {
        return {
          value: c.id,
          text: `${c.name} - ${c.uf}`,
        };
      });
    },
    async getAdCategories() {
      try {
        this.loadingAdCategories = true;

        const res = await this.$axios.get("/posts/categories");

        this.setCategoriesData(res.data);

        this.loadingAdCategories = false;
      } catch (error) {
        this.loadingAdCategories = false;
        this.handleErrors(error);
      }
    },
    setCategoriesData(categories) {
      this.adCategoryOptions = categories.map((c) => {
        return {
          value: c.id,
          text: c.name,
        };
      });
    },
    async getCompanies() {
      try {
        this.loadignCompanies = true;

        const res = await this.$axios.get("/companies");

        this.setCompaniesData(res.data);

        this.loadignCompanies = false;
      } catch (error) {
        this.loadignCompanies = false;
        this.handleErrors(error);
      }
    },
    setCompaniesData(companies) {
      this.companies = companies.map((c) => {
        return {
          value: c.id,
          text: `${c.fantasyName}`,
        };
      });
    },
    async getPlans() {
      try {
        this.loadingPlans = true;

        const res = await this.$axios.get("/plans");

        this.setPlansData(res.data);

        this.loadingPlans = false;
      } catch (error) {
        this.loadingPlans = false;
        this.handleErrors(error);
      }
    },
    setPlansData(plans) {
      this.plans = plans.map((p) => {
        return {
          value: p.id,
          text: `${p.name} (R$ ${parseFloat(p.value).toFixed(2).replace(".", ",")}/mês)`,
        };
      });
    },
    async getPlanFeatures(planId) {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/plans/${planId}/features`);

        this.setPlanFeatures(res.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.handleErrors(error);
      }
    },
    getPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.form.coordinate = `${position.coords.latitude}, ${position.coords.longitude}`;
          },
          () => {
            this.handleErrors("Erro ao pegar localização");
          }
        );
      } else {
        this.handleErrors("Geolocalização não suportada pelo navegador");
        return;
      }
    },
    addPhone() {
      this.phones.push({
        number: null,
        whatsApp: false,
      });
    },
    removePhone(id, index) {
      if (id) {
        this.removePhoneFromApi(id);
      } else {
        this.removePhoneFromArrayByIndex(index);
      }
    },
    removePhoneFromArrayByIndex(index) {
      this.phones.splice(index, 1);
    },
    removePhoneFromArrayById(id) {
      //procura o indice passado como parametro no array de respostas
      let indexFound = this.phones.findIndex((p) => p.id === id);

      //realiza a remoção
      if (indexFound !== -1) this.phones.splice(indexFound, 1);
    },
    async removePhoneFromApi(id) {
      try {
        this.loading = true;

        await this.$axios.delete(`/posts/${this.announcementId}/phones/${id}`);

        this.removePhoneFromArrayById(id);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    toggleWhatsAppFlag(index) {
      this.phones[index].whatsApp = !this.phones[index].whatsApp;
    },
    setAnnouncementData(adData) {
      if (adData.coordinate) adData.coordinate = `${adData.coordinate.coordinates[1]},${adData.coordinate.coordinates[0]}`;
      this.form = { ...adData };
      this.selectedFacilities = this.adFacilities;
      this.phones = this.adPhones;
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  created() {
    this.getFacilities();
    this.getCities();
    this.getAdCategories();
    this.getCompanies();
    this.getPlans();
    this.getTimeIntervals();
  },
  mounted() {
    if (this.mod === "update") {
      this.setAnnouncementData(this.adData);
    }
  },
};
</script>

<style></style>
