<template>
  <div class="payment-methods">
    <v-card :loading="isBusy">
      <v-card-text>
        <div class="mb-3">
          <label
            ><b>Selecione os métodos de pagamento pelos quais atende:</b></label
          >
        </div>

        <div v-bind:key="item.id" v-for="item in optionsPaymentMethods">
          <v-switch
            v-model="selectedPaymentMethods"
            color="primary"
            :label="item.text"
            :value="item.value"
          ></v-switch>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            v-if="completeRegistration"
            class="primary darken-1"
            @click="savePaymentMethods()"
            :loading="isBusy"
          >
            <v-icon right>mdi-check</v-icon> Salvar e finalizar</v-btn
          >

          <v-btn
            v-if="!completeRegistration"
            class="primary darken-1"
            @click="savePaymentMethods()"
            :loading="isBusy"
          >
            <v-icon left>mdi-content-save</v-icon> Salvar</v-btn
          >
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  name: "PaymentMethods",
  data() {
    return {
      isBusy: false,
      selectedPaymentMethods: [],
      optionsPaymentMethods: [],
    };
  },
  props: {
    adPaymentMethods: {
      type: Array,
    },
    completeRegistration: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    announcementId() {
      return this.$route.params.id
        ? this.$route.params.id
        : this.$store.state.adverts.adId;
    },
  },
  methods: {
    async loadPaymentMethods() {
      try {
        this.isBusy = true;

        const res = await this.$axios.get(`/paymentmethods`);

        let arrayPaymentMethods = res.data;

        arrayPaymentMethods.forEach((method) => {
          this.optionsPaymentMethods.push({
            text: method.name,
            value: method.id,
          });
        });

        this.loadAdPaymentMethods();

        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;

        this.handleErrors(error);
      }
    },
    loadAdPaymentMethods() {
      this.selectedPaymentMethods = this.adPaymentMethods.map((p) => {
        return p.AdPaymentMethod.PaymentMethodId;
      });
    },
    async savePaymentMethods() {
      try {
        this.isBusy = true;

        await this.$axios.put(
          `/posts/${this.announcementId}/paymentmethods`,
          this.selectedPaymentMethods
        );

        this.$emit("saved", 3);

        alert("Dados salvos com sucesso")

        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;

        this.handleErrors(error);
      }
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  created() {
    if (!this.create) this.loadPaymentMethods();
  },
};
</script>

<style>
.v-input--selection-controls {
  margin-top: 5px;
  padding-top: 0;
}

.rounded-circle {
  border-radius: 50px;
  width: 25px;
  height: 25px;
  font-size: 12px;
}

.rounded-circle i {
  padding: 0;
  margin: 0;
}
</style>
